<!--
 * @Descripttion: 首页
 * @Author: zyr
 * @Date: 2021-05-27 20:43:31
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-15 15:51:04
-->
<template>
  <div>
    <div class="content-wrapper">
      <!-- 用户信箱 -->
      <div class="common-bg user-label">
        <div class="user-info hack-border-bottom">
          <img :src="info.avatar" alt="" class="user-img flex-shrink"/>
          <div class="user-intro">
            <h5 class="user-name">{{info.name}}</h5>
            <p class="user-title">{{info.duties}}</p>
          </div>
        </div>
        <div class="user-mail">
          <p :class="{'mail-h': !showMore}" v-html="info.descriptions"></p>
          <div class="show-more-wrapper" v-show="info.descriptions.length > 150">
            <img src="../../assets/imgs/show.png" alt="" :class="{'show-more': true, 'rotate-more': showMore}" @click="showMore = !showMore">
          </div>
        </div>
      </div>
      <!-- 功能 -->
      <div class="common-bg menu">
        <div class="menu-item" @click="goLogin('/write')">
          <img src="../../assets/imgs/write.png" alt="" width="100%"/>
        </div>
        <div class="menu-item" @click="goLogin('/reply')">
          <img src="../../assets/imgs/reply.png" alt="" width="100%"/>
        </div>
        <router-link class="menu-item" to="/guide">
          <img src="../../assets/imgs/guide.png" alt="" width="100%"/>
        </router-link>
        <router-link class="menu-item" to="/letter">
          <img src="../../assets/imgs/letter.png" alt="" width="100%"/>
        </router-link>
      </div>
      <!-- 提示 -->
      <p class="home-tips">不受理报警业务，如遇紧急警情请拨打110</p>
      <!-- 轮播 -->
      <van-swipe class="home-swiper" autoplay="3000" indicator-color="#919AB1" v-if="sliders.length">
        <van-swipe-item v-for="v in sliders" :key="v.id">
          <img :src="v.img" alt="" class="swiper-img"/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- <div class="home-footer">
      <p class="text-align">XX提供技术支持</p>
      <a href="tel:0511-3455899" class="footer-phone text-align">0511-3455899</a>
    </div> -->
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { personinfo, sliders } = API
export default {
  name: 'Home',
  data () {
    return {
      info: {
        name: '',
        duties: '',
        status: 1,
        descriptions: '',
        avatar: ''
      },
      sliders: [],
      showMore: false
    }
  },
  watch: {
    $route: function (to) {
      if (to.name === 'Home') this.getUserInfo()
    }
  },
  methods: {
    getUserInfo () {
      const { query: { id } } = this.$route
      this.$axios.get(`${personinfo}${id}`, {
        params: {}
      }).then(({ code, model }) => {
        if (code === 0) {
          this.info = model
          localStorage.setItem('id', model.id)
          this.$store.commit('setId', model.id)
        } else this.$router.replace('/error?type=1')
      })
    },
    getSlider () {
      this.$axios.get(sliders, {
        params: {}
      }).then(({ code, model }) => {
        if (code === 0) {
          this.sliders = model || []
        }
      })
    },
    goLogin (page) {
      if (this.$store.state.token) {
        if (page === '/write') {
          if (!this.info.status || !this.$store.state.ability) return this.$toast('暂不能向此人写信')
          else this.$router.push(page)
        } else this.$router.push(page)
      } else {
        this.$store.commit('setPage', page)
        this.$router.push('/login')
      }
    }
  },
  mounted () {
    this.getUserInfo()
    this.getSlider()
  }
}
</script>
<style lang="less">
  @import '../../assets/less/flex.less';
  .van-swipe__indicator{
    width: .px2rem(18px)[@rem];
    height: .px2rem(10px)[@rem];
    border-radius: .px2rem(5px)[@rem];
    background: #EAECF3;
  }
</style>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
.content-wrapper{
  padding-top: .px2rem(36px)[@rem];
  padding-bottom: .px2rem(36px)[@rem];
  margin: 0 .px2rem(32px)[@rem];
  box-sizing: border-box;
}
.common-bg{
  background: #F6F7FB;
  border-radius: .px2rem(20px)[@rem];
}
.user-label{
  padding: .px2rem(32px)[@rem];
  margin-bottom: .px2rem(32px)[@rem];
}
.menu{
  height: .px2rem(228px)[@rem];
  padding: .px2rem(32px)[@rem] .px2rem(26px)[@rem];
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-item{
  width: .px2rem(136px)[@rem];
  position: relative;
  &::after{
    content: '我要写信';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    color: #101112;
    font-size: .px2rem(28px)[@rem];
  }
  &:nth-child(2)::after{
    content: '回复查询';
  }
  &:nth-child(3)::after{
    content: '操作指南';
  }
  &:last-child::after{
    content: '来信选刊';
  }
}
.home-tips{
  color: #8993A8;
  font-size: .px2rem(24px)[@rem];
  margin: .px2rem(16px)[@rem] 0 .px2rem(64px)[@rem];
  text-align: center;
}
.home-swiper{
  border-radius: .px2rem(20px)[@rem];
  height: .px2rem(250px)[@rem];
  background: #F6F7FB;
}
.swiper-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.show-more-wrapper{
  margin-top: .px2rem(10px)[@rem];
  display: flex;
  justify-content: flex-end;
}
.show-more{
  width: .px2rem(24px)[@rem];
  height: .px2rem(24px)[@rem];
}
.rotate-more{
  transform: rotate(180deg);
}
.mail-h{
  height: .px2rem(480px)[@rem];
  overflow: hidden;
}
</style>
