/*
 * @Descripttion: 接口地址
 * @Author: zyr
 * @Date: 2021-05-30 13:27:48
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-15 15:49:28
 */
const prefix = '/front'
export default {
  personinfo: `${prefix}/personinfo/get/`, // 获取人员信息
  login: `${prefix}/login`, // 登录接口
  getCode: `${prefix}/get/checkcode`, // 验证码
  checkCode: `${prefix}/check/checkcode`, // 验证
  getGuide: `${prefix}/instruction/get`, // 操作说明
  resetPass: `${prefix}/user/resetpwd`, // 重置密码
  getType: `${prefix}/type/query`, // 获取业务类型
  letter: `${prefix}/letter/save`, // 写信
  letterList: `${prefix}/letter/list`, // 写信列表
  replyList: `${prefix}/letter/info/`, // 回复列表
  register: `${prefix}/register`, // 注册
  upload: `${prefix}/upload`, // 上传
  sliders: `${prefix}/sliders`, // 轮播图
  auth: `${prefix}/face/auth`, // 认证请求
  authRes: `${prefix}/face/auth`, // 认证结果请求
  publishList: `${prefix}/letter/publish/list`, // 来信选刊
  publishReply: `${prefix}/letter/publish/info/` // 来信选刊详情
}
